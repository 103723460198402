<template>
  <PageLayout
    pageTitleDividerClass="primary"
    bannerSrc="/assets/image/banner/recipe_banner.png"
    :pageTitle="$t('label.recipe')"
    :pageSubtitle="$t('label.anyOneCanCookWithCookXEcoPack')"
    :hideLogo="true"
    layoutType="x"
  >
    <transition name="fade-in-up">
      <router-view></router-view>
    </transition>
  </PageLayout>
</template>

<script>
  import PageLayout from '@/views/layout/PageLayout';

  export default {
    name: 'Recipe',
    components: {
      PageLayout
    }
  };
</script>

<style lang="scss"></style>
